import { Button } from "@/Components/ui/button";
import { Card } from "@/Components/ui/card";
import { Input } from "@/Components/ui/input";
import { setLoader, toast } from "@/Hooks/Functions";
import { JSONPostRequest } from "@/Hooks/JSONRequest";
import { cn } from '@/lib/utils';
import Loader from "@/Modules/Loader";
import LeonardoAccountSelector from "@/Pages/Prompting/components/LeonardoAccountSelector";
import { Icon } from "@iconify/react";
import { Link, usePage } from "@inertiajs/react";
import { homeAction, promptResponse } from "@state/home";
import { useLocalStorageState } from "ahooks";
import { useAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";

const EachImage = ({ image }) => {
    const onSelect = useCallback(() => {
        homeAction.setSelectedPromptImage(image?.id);
        homeAction.setActiveStep(1);
    }, [image]);

    return <Card data-aos="fade-up" className="col-span-1 overflow-hidden aspect-square group relative flex justify-center items-center md:min-h-[300px] min-h-[200px]">
        <div style={{ backgroundImage: `url("${image.url}")` }} className="hover:scale-105 duration-300 absolute top-0 left-0 bg-cover bg-no-repeat bg-center h-full w-full" />
        <Button onClick={onSelect} variant="theme" size="sm" className="absolute top-4 right-4">Select</Button>
    </Card>
}

export default function EnterPrompt({ auth }) {

    const { accounts } = usePage().props;
    const [response, setResponse] = useAtom(promptResponse);
    const [loading, setLoading] = useState(false);
    const [passReq, setPassReq] = useState(true);
    const [account, setAccount] = useState(accounts?.[0]?.id);
    const [unsavedPrompt, setUnsavedPrompt] = useLocalStorageState('unsaved-prompt', {
        defaultValue: '',
    });

    useEffect(() => {
        if (response?.images?.length > 0) setLoading(false);
    }, [response])

    const submit = () => {
        if (!auth?.user) {
            setPassReq(false);
            return;
        }
        if (!unsavedPrompt) {
            toast.error("Please enter your prompt");
            return;
        }
        JSONPostRequest({
            href: route("leonardo.send-fast-prompt"),
            data: {
                prompt: unsavedPrompt,
                account: account
            },
            onResult: (val) => {
                setResponse(val);
                setLoading(true);
                setUnsavedPrompt("");
            },
            onError: (error) => toast(error?.response?.data?.message, "error"),
            onLoading: (val) => {
                setLoader(val);
            }
        })
    }

    return <div className="w-full flex flex-col gap-4 min-h-[400px]">
        <div className="flex md:flex-row flex-col gap-3 items-center">
            <div className="md:w-fit w-full flex flex-col justify-center items-center p-2 bg-muted-foreground/5 rounded-xl">
                <LeonardoAccountSelector account={account} onAccountChange={setAccount} className="w-full" />
            </div>
            <div className="w-full flex flex-row justify-between items-center p-2 bg-muted-foreground/5 rounded-xl flex-1">
                <span className="whitespace-nowrap mr-2 md:inline-flex hidden text-sm font-semibold">Describe your image</span>
                <Input className="flex-1 rounded-l-full" value={unsavedPrompt} onChange={(e) => setUnsavedPrompt(e.target.value)} placeholder="e.g. Vibrant graphic design pop art piece featuring Amina, the African Queen of Sheba, as a stunning, caramel-skinned, millennial Sufi woman with a mesmerizing, fluorescent beige glow. Her face is a perfect blend of African and Middle Eastern features, with high cheekbones, full lips, and piercing brown eyes that shimmer like the stars in the night sky. Her hair is a wild tangle of curly locks, adorned with intricate, swirling patterns that evoke the mysteries of alchemy. She wears a flowing, iridescent cloak that shimmers with psychedelic colors, reminiscent of the introspective, mystical realms of psylocybin. In the background, a kaleidoscope of colors bursts forth, blending ancient mysticism with futuristic, Space Age vibes. The overall aesthetic is a fusion of 1960s pop art and contemporary, abstract graphic design, with bold lines, geometric shapes, and textured, layered elements that invite the viewer to embark on a cosmic journey of self-discovery and spiritual exploration." />
                <Button disabled={unsavedPrompt.length < 20 || loading} className="rounded-r-full px-4" onClick={submit} size="sm">Submit</Button>
            </div>
        </div>

        {loading && <div className="w-full grid h-96 gap-4 grid-cols-3 justify-center items-center">
            {[1, 2, 3].map((i) => <Loader key={i} text="Generating image. Don`t refresh page." />)}
        </div>}

        {!loading && passReq && <div className="grid w-full md:grid-cols-3 grid-col-start-1 md:p-0 p-4 gap-4">
            {response?.images?.slice(0, 3).map((image, index) => <EachImage image={image} key={index} />)}
        </div>}
        <div id="needLogin" className={cn("w-full grid-cols-2", passReq ? '!hidden' : "!grid !z-[10001]")}>
            <div style={{ backgroundImage: `url("${response?.images?.[0]?.url}")` }} className="bg-cover bg-white bg-no-repeat bg-center h-96 w-full" />
            <div className="h-96 bg-white flex flex-col gap-4 justify-center items-center">
                <div className="flex w-full flex-col gap-2 justify-center items-center">
                    <span className="text-sm">
                        Please login to use this feature
                    </span>
                    <Link href={route("login", { redirect_uri: route('home') })} className="w-1/3 !cursor-pointer inline-flex justify-center items-center">
                        <Button size="sm" className="w-full" variant="theme">Login</Button>
                    </Link>
                </div>
                <div className="flex w-full flex-col gap-2 justify-center items-center">
                    <span className="text-sm">
                        Don't have an account yet?
                    </span>
                    <Link href={route("register", { redirect_uri: route('home') })} className="w-1/3 !cursor-pointer inline-flex justify-center items-center">
                        <Button size="sm" className="w-full" variant="outline" >Register</Button>
                    </Link>
                </div>
            </div>
        </div>
        <Link href={route('prompt.showcase')} className="w-fit mx-auto">
            <Button variant="link" className="font-semibold" >Want to check other prompts and customize? <Icon icon="solar:double-alt-arrow-right-broken" className="text-teal-600" width="20" height="20" /> </Button>
        </Link>
    </div>
}
